import DashboardPropertyStats
    from "@/views/AdminDashboard/AdminDashboardProperty/DashboardPropertyStats/DashboardPropertyStats";
import DashboardChart
    from "@/views/AdminDashboard/AdminDashboardProperty/DashboardChart/DashboardChart.vue";
import SortingDropdown from "@/components/Components/SortingDropdown/SortingDropdown";
import PagePreloader from "@/components/Components/PagePreloader/PagePreloader.vue";

export default {
    name: 'AdminDashboardProperty',
    components: {
        PagePreloader,
        SortingDropdown,
        DashboardChart,
        DashboardPropertyStats
    },
    data() {
        return {
            filterBoxActive: false,
            chartWidth: 450,
            statistics: {
                properties_published: '-',
                property_view_requests: '-'
            },
            statisticsData: [],
            periodOptions: [
                {
                    title: this.$t('last-week'),
                    value: 'week',
                },
                {
                    title: this.$t('last-month'),
                    value: 'month',
                },
                {
                    title: this.$t('last-year'),
                    value: 'year',
                },
            ],
            selectedOption: {
                title: this.$t('last-week'),
                value: '',
            },
            period: 'week',
            lastProperties: [],
            mostRequestedProperties: [],
            isLoading: false,
        }
    },
    async mounted() {
        this.isLoading = true;
        await this.loadStatistics();
        try {
            const properties = await this.$store.dispatch("property/getProperties", {order: 'createdAt.DESC'});
            this.lastProperties = properties.map((item) => {
                return {
                    property: item,
                    count: '--'
                }
            })
            this.mostRequestedProperties = await this.$store.dispatch("property/getProperties");
            this.isLoading = false;
        } catch (err) {
            this.isLoading = false;
        }

        this.handlePeriodChange(this.period);
    },
    methods: {
        async loadStatistics() {

            this.statistics = await this.$store.dispatch("user/getStatistics");
            let vm = this;

            this.statisticsData = [];
            if (!this.statistics.hasOwnProperty('propertyViews') || this.statistics.propertyViews[this.period].length === 0) {
                return;
            }

            this.statistics.propertyViews[this.period].forEach(item => {
                if (vm.period === 'year') {
                    vm.statisticsData.push({
                        name: item.year + '-' + String(item.month).padStart(2, '0'),
                        pl: item.count
                    })
                } else {
                    vm.statisticsData.push({name: item.date, pl: item.count});
                }
            });

            if (this.period === 'year') {
                this.statisticsData = this.addMissingMonths(this.statisticsData);
            } else if (this.period === 'week') {
                this.statisticsData = this.addMissingDays(this.statisticsData, 7);
            } else {
                this.statisticsData = this.addMissingWeeks(this.statisticsData, 5);
            }

            this.statistics.propertyViews[this.period].sort((a, b) => {
                if (vm.period === 'year') {
                    return parseInt(a.year + '' + a.month) > parseInt(b.year + '' + b.month);
                } else {
                    const aDate = new Date(a.date);
                    const bDate = new Date(b.date);
                    return aDate.getTime() - bDate.getTime();
                }
            })
        },
        addMissingWeeks(data, weeksBack) {
            // Helper function to format date as YYYY-WW
            const formatWeek = (date) => {
                date = new Date(date);
                const year = date.getFullYear();
                const week = Math.ceil((((date - new Date(year, 0, 1)) / 86400000) + 1) / 7);
                console.log(year, week);
                return `${year}-W${String(week).padStart(2, '0')}`;
            };

            console.log(data);

            // Parse existing dates and find the latest date
            const latestDate = new Date(Math.max(...data.map(obj => {
                const [year, week] = obj.name.split('-W');
                return new Date(parseInt(year), 0, 1 + (parseInt(week) - 1) * 7);
            })));


            // Generate the last `weeksBack` weeks from the latest date
            const weeks = Array.from({length: weeksBack}, (_, i) => {
                const date = new Date(latestDate);
                date.setDate(date.getDate() - i * 7);
                console.log(date);
                return formatWeek(date);
            });

            // Convert data array to a map for quick lookup and add missing weeks
            const dataMap = new Map(data.map(obj => [obj.name, obj]));
            weeks.forEach(week => {
                if (!dataMap.has(week)) {
                    data.push({name: week, pl: 0});
                }
            });

            // Sort the array by date (name)
            data.sort((a, b) => {
                const [yearA, weekA] = a.name.split('-W');
                const [yearB, weekB] = b.name.split('-W');
                return new Date(parseInt(yearA), 0, 1 + (parseInt(weekA) - 1) * 7) - new Date(parseInt(yearB), 0, 1 + (parseInt(weekB) - 1) * 7);
            });

            return data;
        },
        addMissingMonths(data) {
            // Helper function to format date as YYYY-MM
            const formatDate = (year, month) => `${year}-${String(month).padStart(2, '0')}`;

            // Parse existing dates and find the latest date
            const latestDate = new Date(Math.max(...data.map(obj => new Date(obj.name + "-01"))));

            // Generate the last 12 months from the latest date
            const months = Array.from({length: 12}, (_, i) => {
                const date = new Date(latestDate);
                date.setMonth(latestDate.getMonth() - i);
                return formatDate(date.getFullYear(), date.getMonth() + 1);
            });

            // Convert data array to a map for quick lookup and add missing months
            const dataMap = new Map(data.map(obj => [obj.name, obj]));
            months.forEach(month => {
                if (!dataMap.has(month)) {
                    data.push({name: month, pl: 0});
                }
            });

            // Sort the array by date (name)
            data.sort((a, b) => new Date(a.name + "-01") - new Date(b.name + "-01"));

            return data;
        },
        addMissingDays(data, daysBack) {
            // Helper function to format date as YYYY-MM-DD
            const formatDate = (date) => date.toISOString().split('T')[0];

            // Parse existing dates and find the latest date
            const latestDate = new Date(Math.max(...data.map(obj => new Date(obj.name))));

            // Generate the last `daysBack` days from the latest date
            const days = Array.from({length: daysBack}, (_, i) => {
                const date = new Date(latestDate);
                date.setDate(latestDate.getDate() - i);
                return formatDate(date);
            });

            // Convert data array to a map for quick lookup and add missing days
            const dataMap = new Map(data.map(obj => [obj.name, obj]));
            days.forEach(day => {
                if (!dataMap.has(day)) {
                    data.push({name: day, pl: 0});
                }
            });

            // Sort the array by date (name)
            data.sort((a, b) => new Date(a.name) - new Date(b.name));

            return data;
        },
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(option) {
            this.toggleFilterBox();
            this.selectedOption = option;
        },
        handlePeriodChange(period) {
            this.period = period;
            this.loadStatistics();
        }
    },
}
